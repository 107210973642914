<template>
  <div class="artifact-detail-view">
    <div class="head">
      <v-icon @click="closeDialog">
        mdi-close
      </v-icon>
    </div>

    <div class="body">
      <div class="main">
        <div class="detail">
          <div class="info">
            <p>作成日</p>
            <p>{{ artifact?.createDate }}</p>
          </div>
          <div class="images info">
            <ImageTagList />
          </div>

          <div class="divider" />

          <button
            class="btn"
            @click="handleOpenDialog"
          >
            ダウンロード
            <v-icon>
              mdi-download
            </v-icon>
          </button>
        </div>

        <div class="artifact">
          <SlideBeforeAfter
            v-if="artifact"
            :before-image="artifact?.originalSource"
            :after-image="artifact?.source"
          />
        </div>
      </div>

      <div class="sub">
        <NuxtImg
          class="img"
          :src="img.source"
          v-for="img in artifacts"
          :key="img.id"
          @click="getArtifact(img.id)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])
const dialogStore = useDialogStore()
const artifactStore = useArtifactStore()
const artifact = computed(() => artifactStore.displayedArtifact)
const artifacts = computed(() => artifactStore.filteredArtifacts)

const handleOpenDialog = async () => {
  if (artifact.value) {
    const { id, source, isDownloaded } = artifact.value
    dialogStore.openDownloadImageDialog({
      id,
      src: source,
      isDownloaded,
    })
  }
}

const getArtifact = async (id: number) => {
  await artifactStore.getArtifactWithTags(id)
}

const closeDialog = () => {
  emit('close')
  artifactStore.displayedArtifact = undefined
}

onBeforeRouteLeave(() => {
  closeDialog()
})
</script>

<style lang="scss" scoped>
.artifact-detail-view {
  width: 80%;
  height: auto;
  gap: 1rem;
  padding: 1.5rem;
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--v-theme-primary));

  @include media(sm) {
    width: 95%;
    height: 95%;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  > .head {
    align-self: flex-end;
  }

  > .body {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    background-color: rgb(var(--v-theme-background));

    > .main {
      width: 100%;
      height: 80%;
      gap: 1rem;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      background-color: rgb(var(--v-theme-background));

      @include media(sm) {
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem;
        flex-direction: column-reverse;
      }

      > .detail {
        width: 25%;
        height: 100%;
        gap: 2rem;
        padding: 1.5rem 0.5rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::-webkit-scrollbar {
          width: 2px;
          display: block;
          background-color: rgb(var(--v-theme-primary));
        }

        &::-webkit-scrollbar-thumb {
          width: 1px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.2);
        }

        @include media(sm) {
          flex-grow: 1;
          width: 100%;
          height: auto;
          gap: 1rem;
          padding: 1.5rem 0.5rem;
        }

        > .info {
          gap: 0.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;

          > p {
            font-size: 0.75rem;
          }
        }

        > .images.info {
          flex: 1 auto;
          overflow-y: scroll;
          min-height: 100px;
        }

        > .divider {
          width: 100%;
          height: 1px;
          background-color: #ffffff;
        }

        > .btn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          padding: 0.5rem;
          border-radius: 0.25rem;
          background-color: rgb(var(--v-theme-primary-light));

          @include media(sm) {
            padding: 0.25rem;
            font-size: 0.685rem;
          }
        }
      }

      > .artifact {
        width: 75%;
        height: 100%;

        @include media(sm) {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
      }
    }

    > .sub {
      width: 100%;
      height: 20%;
      display: flex;
      padding: 1rem;
      gap: 0.5rem;
      overflow-x: scroll;
      background-color: rgb(var(--v-theme-background));
      @include media(sm) {
        gap: 0.25rem;
        padding: 0.5rem;
        flex-wrap: nowrap;
      }
      > .img {
        width: auto;
        height: 100%;
        cursor: pointer;
        object-fit: cover;
        aspect-ratio: 16 / 9;
      }
    }
  }
}
</style>
